<script>
    import V from 'voUtils/V.js'



    export default {
    	voVueComponent: 'owl-carousel',
    	props: {
    		carouselId: String,
    		items: {
    			type: String,
    			default: "1"
    		},
    		loop: {
    			type: String,
    			default: "false"
    		},
    		autoplay: {
    			type: String,
    			default: "false"
    		},
    		dots: {
    			type: String,
    			default: "false"
    		},
    		speed: {
    			type: String,
    			default: "3000"
    		},
    		nav: {
    			type: String,
    			default: "false"
    		},
    		slidemargin: {
    			type: String,
    			default: '0'
    		}
    	},

    	mounted: function() {

    		var itemsVal = parseInt(this.items);
    		var marginVal = parseInt(this.slidemargin);
    		var loopVal = (this.loop == 'true');
    		var autoplayVal = (this.autoplay == 'true');
    		var dotsVal = (this.dots == 'true');
    		var speedVal = parseInt(this.speed);
    		var navVal = (this.nav == 'true');


    		$("#owl-" + this.carouselId).owlCarousel({
    			items: itemsVal,
    			margin: marginVal,
    			loop: loopVal,
    			autoplay: autoplayVal,
    			dots: dotsVal,
    			smartSpeed: speedVal,
    			nav: navVal,
    			autoplayTimeout: 10000
    		});
    	}
    };
</script>