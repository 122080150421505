var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "dlg", staticClass: "dlg", class: { show: _vm.showing } },
    [
      _c("div", { staticClass: "box layout-column" }, [
        _c("div", { staticClass: "header" }, [
          _vm.showCloseButtonInHeader == "true"
            ? _c(
                "a",
                {
                  staticClass: "close-btn",
                  attrs: { href: "javascript:void('0');" },
                  on: {
                    click: function($event) {
                      return _vm.onCloseBtnClick()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-times",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("h4", [_vm._v(_vm._s(_vm.headerLabel))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "body flex" }, [_vm._t("default")], 2),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.applyLabel,
                expression: "applyLabel"
              }
            ],
            staticClass: "footer"
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.onCloseBtnClick()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.applyLabel))]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }